







import Vue from 'vue'
import { getPlaidLinkToken } from '@api/wallet'
import { getPlaidLinkToken as getPlaidLinkTokenForEntity } from '@api/addy-plus/wallet'
import { IData, IMethods, IComputed, IProps } from '@/types/components/plaid/initializer'

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  data() {
    return {
      openingPlaid: false,
    }
  },
  props: {
    ownable: {
      type: String,
      default: 'personal',
    },
  },
  computed: {
    nextPageRouteMap() {
      return {
        personal: '/wallet/accounts/confirmBankDetailsThroughPlaid',
        business: `${this.$route.path?.replace('/new', '/confirmAccountDetails')}`,
      }
    },
    confirmPageRoute() {
      return this.nextPageRouteMap[this.ownable as 'personal' | 'business']
    },
    corporationId() {
      return this.$route.params.corporationId || ''
    },
  },
  mounted() {
    this.insertPlaidScript()
  },
  methods: {
    insertPlaidScript() {
      const plaidSdk = document.getElementById('plaid-script')
      if (!plaidSdk) {
        const plaidScript = document.createElement('script')
        plaidScript.id = 'plaid-script'
        plaidScript.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
        document.body.appendChild(plaidScript)
      }
    },
    async initializePlaid() {
      if (this.openingPlaid) return
      this.openingPlaid = true

      const data = await (this.ownable === 'personal' ? getPlaidLinkToken() : getPlaidLinkTokenForEntity(this.corporationId))
      if (!data.success) return

      const handler = (window as any).Plaid.create({
        token: data.data.plaidLinkToken,
        onSuccess: this.onSuccess,
        onExit: () => handler.destroy(),
      })
      handler.open()
      this.openingPlaid = false
    },
    onSuccess(publicToken, metadata) {
      this.$router.push({
        path: this.confirmPageRoute,
        query: {
          token: encodeURIComponent(publicToken),
          accountId: encodeURIComponent(metadata.accounts[0].id),
          sessionId: encodeURIComponent(metadata.link_session_id),
        },
      })
    },
  },
})
